@import 'src/theme/xtrf/index';

.wrapper {
  padding: 0 30px 40px;
  text-align: center;
}

.link {
  text-align: left;
  margin-bottom: 40px;
  & a {
    color: $primary-grey-color;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
    &:hover,
    &:active {
      color: $text-secondary-color;
      border-bottom: 1px solid $text-secondary-color;
    }
  }
}
