@import 'src/theme/xtrf/index';

.container {
  padding: 0 30px 40px;
  position: relative;
}

.titleWrapper {
  margin-bottom: 30px;
  border-bottom: 1px solid $fifteenth-grey-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
}

.title {
  margin-bottom: 0 !important;
}
