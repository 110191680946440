@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700&display=swap&subset=latin-ext');
@import 'variables';

html,
body {
  overflow: hidden;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-out-in {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.fade-enter {
  opacity: 0;
  font-size: 16px;
}
.fade-enter-active {
  opacity: 1;
  font-size: 36px;

  transition: all 1000ms;
}
.fade-exit {
  opacity: 1;
  font-size: 36px;
}
.fade-exit-active {
  opacity: 0;
  font-size: 16px;

  transition: all 1000ms;
}

#xtrfChat {
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  padding: 0;
  margin: 0;
  background: transparent;
  pointer-events: none;
}

.checkbox > label {
  &:before {
    border: $input-border !important;
    width: 16px !important;
    height: 16px !important;
    border-radius: 0 !important;
  }
  &:hover:before {
    border: 1px solid $eighth-grey-color !important;
  }
}

.checked.checkbox > label {
  @extend .checkbox;
  &:before {
    border: 1px solid $primary-color-1 !important;
  }
  &:hover:before {
    border: 1px solid $primary-color-2 !important;
    color: $primary-color-2 !important;
  }
  &:after {
    color: $primary-color-1 !important;
    font-size: 13px !important;
  }
  &:hover:after {
    color: $primary-color-2 !important;
  }
}

.checked.disabled.checkbox > label {
  &:after {
    background-color: $third-grey-color;
    color: $tenth-grey-color !important;
    width: 16px !important;
    height: 16px !important;
    font-size: 13px !important;
    border: 1px solid #d3d3d3;
  }
}

select {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #222;
}
