@import 'src/theme/xtrf/index';


.container {
  display: flex;
  flex-direction: column;
}

.mainContent {
  display: flex;
  padding: 5px;
  background-color: #F0F0F0;
  align-items: center;
  justify-content: space-between;
}


.name {
  color: $text-secondary-color;
  font-size: 13px;
}

.loader {
  margin-right: 8px !important;
  margin-left: 5px !important;
}

.cancelIcon {
  font-size: 1.1em !important;
  margin-left: 5px !important;
  margin-top: -2px !important;
  color: $error-color !important;
  cursor: pointer;
}

.progress {
  background-color: rgb(218, 218, 218) !important;
  :global .bar {
    min-width: 0 !important;
    background-color: $primary-color-1 !important;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
}
