@import 'src/theme/xtrf/index';

.wrapper {
  padding: 15px 10px;
  animation: fadeIn 0.3s ease-out;
}

.headerTitle {
  font-size: 15px;
  font-weight: $h2-font-weight;
}

.removeAvatarWrapper {
  margin-top: 4px;
  font-size: 13px;
}

.link {
  color: inherit !important;
  border-bottom: 1px dashed;
  cursor: pointer;
  opacity: 1 !important;
}

.termsAndConditions {
  color: inherit !important;
  opacity: 1 !important;
}

.button {
  position: relative;
  display: block;
  margin: 0 auto 20px auto !important;
  width: 100%;
  padding: 7px 0 !important;
  border: 1px solid $secondary-grey-color;
  border-radius: 3px;
  min-height: 30px;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: $third-grey-color;
  }
  &:active {
    transform: translateY(1px);
  }
}

.blueButton {
  @extend .button;
  color: white !important;
  background-color: $primary-color-1 !important;
  border: none !important;
  &:hover,
  &:focus {
    background-color: $primary-color-2 !important;
  }
  &:active {
    background-color: $primary-color-2 !important;
  }
}

.disabledButton {
  @extend .button;
  @extend .blueButton;
  background-color: $secondary-grey-color !important;
  &:hover {
    background-color: $secondary-grey-color !important;
  }
}

.checkbox > label {
  @extend .checkbox;
}

.checkboxChecked > label {
  @extend .checkbox;
  &:before {
    border: 1px solid $primary-color-1 !important;
  }
  &:hover:before {
    border: 1px solid $primary-color-1 !important;
    color: $primary-color-1 !important;
  }
  &:hover:after {
    color: $primary-color-1 !important;
  }
}

.checkboxTitle {
  font-size: $h5-font-size;
  font-weight: $h2-font-weight;
  margin-bottom: 15px;
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.checkboxWrapper {
  margin-bottom: 5px;
}

.userNameInput {
  width: 100%;
  opacity: 1 !important;
  height: 34px !important;
  border: $input-border;
  color: $input-color !important;
  border-radius: 0 !important;
  &:hover {
    border: $hover-input-border;
  }
  &:focus {
    outline: $focus-input-border !important;
    outline-offset: 0px;
  }
}

.userNameInputError {
  @extend .userNameInput;
  border: $input-error-border;
  &:hover {
    border: $input-error-border;
  }
  &:focus {
    outline: $focus-input-border !important;
    outline-offset: 0px;
  }
}

.inputError {
  font-size: $small-input-font-size;
  color: $error-color;
  margin-top: $spacing-very-small;
}

.userWrapper {
  display: flex;
  align-items: center;
}

.formContainer {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.flex {
  flex: 0 0 auto;
}

