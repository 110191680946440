@import 'src/theme/xtrf/index';

.label {
  margin-bottom: 15px;
  font-weight: $h3-font-weight;
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.globalChatLabel {
  font-size: $h5-font-size;
  font-weight: $h2-font-weight;
  margin: 0 0 15px;
  &:not(:first-child) {
    margin-top: 20px;
  }
}
