.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #999;
  margin: 0 5px;
  display: inline-flex;
}

.filled {
  background-color: #999;
}

.chatRoomIcon {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 3px;
  left: 4px;
}
