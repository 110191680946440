.formContainer {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

.formFieldWrapper {
  margin-bottom: 15px;
  min-height: 35px;
}

.sendMessageButton {
  margin-right: 10px;
}


