@import 'src/theme/xtrf/index';

.appWrapper {
  padding: 15px;
  display: grid;
  grid-template-columns: 300px 1fr 200px;
  align-items: center;
  border: 1px solid $secondary-grey-color;
  border-radius: 4px;
  margin-bottom: 20px;
}

.appTitleWrapper {
  display: flex;
  align-items: center;
}

.appTitle {
  margin-left: 15px;
  font-weight: 600;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.settingsButton {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.icon {
  width: 35px;
  height: 35px;
}

.activeText {
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
  margin-left: 10px;
}

.activeTextON {
  color: $success-color;
}

.activeTextOFF {
  color: $error-color;
}

.nothingToShowText {
  padding-top: 15px;
  text-align: center;
}
