@import 'src/theme/xtrf/index';

.registerInfo {
  border-top: 1px solid $secondary-grey-color;
  margin-top: 20px;
  padding-top: 20px;
  text-align: left;
}

.noAccount {
  font-size: 18px;
  display: inline-block;
  margin-bottom: 20px;
  color: $primary-grey-color;
}

.learnMore {
  white-space: pre-wrap;
  font-size: $h4-font-size;
  color: $fifth-grey-color;
}
