.wrapper {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  flex-direction: row-reverse;
  align-items: flex-end;
  padding-bottom: 15px;
  & > div {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
  }
}
