@import 'src/theme/xtrf/index';

.container {
  margin: 5px;
  display: inline-flex;
  flex-direction: column;
}

.mainContent {
  display: inline-flex;
  padding: 5px;
  background-color: #f0f0f0;
  border-radius: 4px;
  align-items: center;
  align-self: flex-start;
}

.errorContent {
  border: 1px solid $error-color;
  background-color: transparent;
  .name {
    color: $error-color;
    margin-left: 5px;
  }
}

.mainContentUploading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.errorMessage {
  margin: 2px 0px;
  color: $error-color;
  font-size: 13px;
  max-width: 220px;
}

.fileIcon {
  font-size: 1.3em !important;
  color: rgb(110, 101, 100) !important;
  height: auto !important;
}

.name {
  color: $text-secondary-color;
  font-size: 13px;
}

.loader {
  margin-right: 8px !important;
}

.deleteIcon {
  font-size: 1.1em !important;
  margin-left: 5px !important;
  margin-top: -2px !important;
  color: $error-color !important;
  cursor: pointer;
}

.errorIcon {
  font-size: 1.3em !important;
  color: $error-color;
}

.progress {
  background-color: rgb(218, 218, 218) !important;
  :global .bar {
    background-color: $primary-color-1 !important;
  }
}

.header {
  display: flex;
  align-items: center;
}
