@import 'src/theme/xtrf/index';

.wrapper {
  padding: 0 30px;
  padding-bottom: 40px;
  font-size: 16px;
  text-align: left;
}

.submitButton {
  margin-bottom: 10px !important;
  background-color: $primary-color-1 !important;
  color: white !important;
  margin: 0 auto !important;
  display: block !important;
}

.workspaceName {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
  word-break: break-word;
}

.roleInWorkspace {
  margin-bottom: 30px;
}

.workspaceId {
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loaderContainer {
  padding: 30px;
  position: relative;
}
