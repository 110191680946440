.workspaceName {
  font-weight: 800;
  word-break: break-word;
  white-space: normal;
}

.url {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
