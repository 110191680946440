@import 'src/theme/xtrf/index';

.wrapper {
  padding: 0 30px;
  padding-bottom: 40px;
  position: relative;
}

.submitButton {
  display: block !important;
  margin-bottom: 12px !important;
  min-width: 155px !important;
  min-height: 40px !important;
}

.passwordReset {
  margin-bottom: 40px;
  text-align: left;
  & a {
    color: $primary-grey-color;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
    &:hover,
    &:active {
      color: $text-secondary-color;
      border-bottom: 1px solid $text-secondary-color;
    }
  }
}

.input {
  &:focus-within {
    outline: $focus-input-border !important;
  }
  & > div {
    opacity: 1 !important;
    height: 34px !important;
    border: $input-border;
    &:hover {
      border: $hover-input-border;
    }
  }
  & > div > input {
    color: $input-color !important;
    border-radius: 0 !important;
    border: none !important;
    &::placeholder {
      color: $input-placeholder-color !important;
    }
  }
}

.loginInput {
  margin-bottom: 10px !important;
  @extend .input;
}

.passwordInput {
  margin-bottom: 20px !important;
  @extend .input;
}

.header {
  margin: 0 0 20px !important;
  font-weight: 400 !important;
}

.icon {
  position: absolute;
  top: 4px;
  left: 5px;
  fill: $input-placeholder-color;
}
