@import 'src/theme/xtrf/index';

.container {
  padding: 3px 10px;
  transition: background-color 0.3s ease-out;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $third-grey-color;
  }
}

.appName {
  margin: 0 10px;
  color: $primary-grey-color !important;
  word-break: break-all;
  font-weight: $h1-font-weight;
}

.appIcon {
  width: 16px;
  opacity: 0.7;
}

.dropdownContainer {
  margin-left: auto;
}

.dropdownVisible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.dropdownHidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
