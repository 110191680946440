@import '../globals/variables';

.ui.popup {
  border-radius: 3px;
  background-color: $black !important;
  color: $secondary-grey-color !important;
  text-align: center;
  &::before {
    background-color: $black !important;
  }
}
