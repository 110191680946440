@import 'src/theme/xtrf/index';

.chatInputContainer {
  width: 100%;
  border-top: $input-border;
  line-height: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative;
}

.uploadInputContainer {
  cursor: pointer;
  padding: 0 3px 9px 5px;
  align-self: flex-end;
}

.uploadInput {
  outline: none;
}

.textarea {
  min-height: 25px;
  width: 100%;
  border: none;
  padding: 10px 70px 10px 10px;
  font-family: $font-family;
  color: $text-secondary-color;
  resize: none;
  &:focus {
    outline: none;
  }
}

.icon {
  position: absolute;
  bottom: 10px;
  font-size: 1.5rem !important;
  cursor: pointer;
}

.iconEmoji {
  @extend .icon;
  right: 44px;
  fill: $sixth-grey-color;
  &:hover {
    fill: $seventh-grey-color;
  }
}

.iconSend {
  @extend .icon;
  right: 14px;
  fill: $sixth-grey-color;
}

.iconSendActive {
  @extend .iconSend;
  fill: $primary-color-2;
}
