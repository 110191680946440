@import 'src/theme/xtrf/index';

.title {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px !important;
  font-size: $h3-font-size !important;
  color: $text-fourth-color !important;
  background-color: $sixteenth-grey-color;
  border-top: 1px solid $thirteenth-grey-color;
  border-bottom: 1px solid $thirteenth-grey-color;
}

.titleActive {
  font-weight: 600;
}

.toggleIcon {
  font-size: 15px !important;
}

.unreadMessages {
  margin: 0 10px;
  visibility: visible;
  opacity:1;
  transition: visibility 0s linear 0s, opacity 1s;

}

.unreadMessagesHidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.content {
  padding-top: 0!important;
}
