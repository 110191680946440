@import 'src/theme/xtrf/index';

.infoText {
  display: block;
  margin-top: 25px;
  margin-bottom: 15px;
}

.label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
}

.input {
  width: 60%;
}

.saveDomainsButtonWrapper {
  padding-top: 10px;
}

.titleWrapper {
  display: relative;
}

.loaderWrapper {
  position: relative;
  left: 85px;
  bottom: 15px;
}

.saveButton {
  width: 140px;
}
