@import 'src/theme/xtrf/index';

.featureButton {
  font-size: 13px;
  flex-basis: 100%;
  background-color: #fff;
  border: 1px solid $secondary-grey-color;
  border-left: none;
  cursor: pointer;
  transition: all 0.3s;
  color: $text-primary-color;
  height: 55px;
  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: $third-grey-color;
  }
}

.icon {
  font-size: 18px !important;
  fill: $text-primary-color;
  transition: all 0.3s;
}

.active {
  color: $primary-color-1;
}

.activeIcon {
  fill: $primary-color-1;
}

.testMode {
  border-top: none;
}
