@import '../../../../../theme/xtrf/index';

.formContainer {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  animation: fadeIn 0.3s ease-out;
}

.formFieldWrapper:not(:last-child) {
  margin-bottom: 15px;
  min-height: 35px;
}

.formContainerHighlighted {
  background-color: $seventh-red-color;
  transition: background-color 1000ms ease-in;
}
.checkboxLabel {
  max-width: 300px;
}
