@import 'src/theme/xtrf/index';

.error {
  margin: 7px 10px !important;
}

.resendInvitationContainer {
  position: relative;
  padding: 30px;
  animation: fadeIn 0.3s ease-out;
}

.container {
  padding: 0 0 10px;
}
