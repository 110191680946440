@import '../globals/variables';

.confirm {
  height: 30px;
  :global {
    .actions {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  & > :nth-child(1) {
    font-size: 18px !important;
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }
  & > :nth-child(2) {
    padding-top: 10px !important;
  }
  & > :nth-child(3) {
    display: flex;
    justify-content: flex-end;
    & > :nth-child(2) {
      margin-left: 7px !important;
      color: white !important;
      background-color: $primary-color-1 !important;
      border: none !important;
      &:hover,
      &:focus {
        background-color: $primary-color-2 !important;
      }
      &:active {
        background-color: $primary-color-2 !important;
      }
    }
  }
}
