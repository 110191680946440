@import 'src/theme/xtrf/index';

.container {
  background-color: hsla(0, 0%, 100%, 1);
  height: 100%;
  border-radius: 0px;
  border-width: 0px 0px 0px 3px;
  border-color: rgba(94, 94, 94, 0.27);
  border-style: solid;
  display: flex;
  flex-direction: column;
}

.resendInvitationContainer {
  position: relative;
  padding: 30px;
  animation: fadeIn 0.3s ease-out;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px 15px 15px;
  & > h2 {
    margin: 0 !important;
  }
}

.modalHeader {
  margin-top: unset !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.headerTag {
  min-height: 48px;
  display: flex;
  align-items: center;
}

.headerContent {
  font-size: 20px !important;
  line-height: 26px !important;
  padding-left: 60px;
}

.chatIcon {
  position: absolute;
}

.iconAndName {
  display: flex;
  align-items: center;
}

.name {
  font-size: 24px;
  margin-right: 5px;
}

.icon {
  width: 25px;
  margin-right: 5px;
}

.projectListsWrapper {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: $rootFontSize;
}

.workspaceSettingsWrapper {
  font-size: $rootFontSize;
  height: calc(100vh - 137px);
  display: flex;
  flex-direction: column;
}

.workspaceSettingsWrapperReduced {
  font-size: $rootFontSize;
  height: calc(100vh - 197px);
  display: flex;
  flex-direction: column;
}

.workspaceLine {
  height: 1px;
  border: none;
  background-color: gray;
}

.unreadMessageContainer {
  min-width: 40px;
}

.button {
  width: 100%;
  margin: 20px auto !important;
  display: block !important;
  height: 30px !important;
  font-size: $normal-input-font-size !important;
}

.blueButton {
  @extend .button;
  background-color: $primary-color-1 !important;
  color: white !important;
  margin-top: 40px !important;
  border: none !important;
  &:hover,
  &:focus {
    background-color: $primary-color-2 !important;
  }
  &:active {
    background-color: $primary-color-2 !important;
  }
}

.disabledButton {
  @extend .button;
  @extend .blueButton;
  background-color: $secondary-grey-color !important;
  &:hover {
    background-color: $secondary-grey-color !important;
  }
}

.checkbox {
  &:before {
    border: $input-border !important;
    width: 16px !important;
    height: 16px !important;
    border-radius: 0 !important;
  }
  &:hover:before {
    border: 1px solid $eighth-grey-color !important;
  }
  &:after {
    color: $primary-color-1 !important;
    font-size: 13px !important;
  }
}

.checkbox > label {
  @extend .checkbox;
}

.checkboxChecked > label {
  @extend .checkbox;
  &:before {
    border: 1px solid $primary-color-1 !important;
  }
  &:hover:before {
    border: 1px solid $primary-color-2 !important;
    color: $primary-color-2 !important;
  }
  &:hover:after {
    color: $primary-color-2 !important;
  }
}

.checkboxTitle {
  margin-bottom: 15px;
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.confirmWrapper {
  pointer-events: all;
  width: 100%;
  background-color: #fff;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 20px;
  animation: fadeIn 0.3s ease-out;
}

.leaveButton {
  color: white !important;
  background-color: $primary-color-1 !important;
  border: none !important;
  margin-right: 5px !important;
}

.cancelButton {
  margin-left: 5px !important;
}

.confirmButtonsWrapper {
  width: 100%;
  margin-top: 10px;
}

.confirmContent {
  margin-bottom: 10px;
}

.workspacePreferences {
  padding: 15px 10px;
  animation: fadeIn 0.3s ease-out;
}

.error {
  margin: 0 10px 7px !important;
}
