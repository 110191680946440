@import 'src/theme/xtrf/index';

.modal {
  border: 1px solid $secondary-grey-color !important;
  border-radius: 0px !important;
  width: 350px;
  @media (max-width: 350px) {
    width: 90% !important;
  }
}
