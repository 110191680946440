@import 'src/theme/xtrf/index';

.mainContentColumn {
  height: 100vh;
  width: calc(100% - 350px);
  position: relative;
  @media only screen and (max-width: 1340px) {
    width: calc(100% - 300px);
  }
}

.sideBarColumn {
  display: block;
  width: 350px;
  position: relative;
  max-height: 100vh;
  @media only screen and (max-width: 1340px) {
    width: 300px;
  }
}

.slideOutIn {
  animation: slide-out-in 0.6s ease-out;
}
.slideIn {
  animation: slide-in 0.3s ease-out;
}

.fade-enter {
  opacity: 0;
  font-size: 16px;
}
.fade-enter-active {
  opacity: 1;
  font-size: 36px;

  transition: all 1000ms;
}
.fade-exit {
  opacity: 1;
  font-size: 36px;
}
.fade-exit-active {
  opacity: 0;
  font-size: 16px;

  transition: all 1000ms;
}

.linkButton {
  position: absolute;
  bottom: 40px;
  left: 40px;
  display: inline-block;
  border: 1px solid $secondary-grey-color;
  border-radius: 3px;
  padding: 14px 40px;
  background-color: $light;
  cursor: pointer;
  align-self: center;
  margin-top: 5px;
  color: $text-primary-color;
  text-align: center;

  &:hover {
    background-color: $third-grey-color;
    color: inherit;
  }
}
