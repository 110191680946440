@import 'src/theme/xtrf/index';

.wrapper {
  padding: 0 30px;
  padding-bottom: 40px;
  position: relative;
  text-align: left;
}

.button {
  margin-top: 15px !important;
  margin-right: 15px !important;
  padding: 0 10px !important;
}

.submitButton {
  background-color: $primary-color-1 !important;
  border-color: $primary-color-1 !important;
  color: white !important;
}

.header {
  margin: 20px 0 20px !important;
  font-weight: 600 !important;
}
