@import 'src/theme/xtrf/index';

.projectHeader {
  display: flex;
  margin-bottom: 5px;
  margin-top: 0;
}

.id {
  white-space: nowrap;
  color: $fifth-grey-color;
  font-weight: 600;
  margin-right: 10px;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  font-weight: 600;
  margin-right: 10px;
}

.separator {
  height: 1px;
  border: none;
  background-color: $secondary-grey-color;
  margin: 0;
}

.projectItem {
  margin-bottom: 5px;
  animation: fadeIn 0.3s ease-out;

  &:last-child {
    margin-bottom: 15px;
  }
}

.accordionTitle {
  padding: 0 10px !important;
}

.accordionContent {
  padding-top: 12px !important;
}
