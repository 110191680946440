.dropdownItem {
  min-width: 220px;
}

.modalHeader {
  margin-top: unset !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
