@import 'src/theme/xtrf/index';

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  background-color: $icon-wrapper;
}

.preliminaryWrapper {
  background-color: $preliminary-icon-wrapper;
}

.icon {
  width: 60px;
  height: 60px;
  fill: $icon;
}

.preliminaryIcon {
  fill: $preliminary-icon;
}
