@import 'src/theme/xtrf/index';

.closeIcon {
  fill: $primary-grey-color !important;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.header {
  margin-top: unset !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 16px;
  font-weight: $h2-font-weight;
  margin-bottom: 20px;
}

.content {
  font-size: 13px;
  font-family: $font-family;
}

.input {
  @extend .content;
  padding: 0 5px;
  margin-top: 10px;
  width: 100%;
  height: 34px !important;
  border: $input-border;
  &:hover {
    border: $hover-input-border;
  }
  &:focus {
    outline: $focus-input-border !important;
  }
}

.button {
  display: block !important;
  margin: 10px auto !important;
  width: 70%;
  color: white !important;
  background-color: $primary-color-1 !important;
  border: none !important;
  margin: 30px auto 0 !important;
  &:hover,
  &:focus {
    background-color: $primary-color-2 !important;
  }
  &:active {
    background-color: $primary-color-2 !important;
  }
}

.or {
  text-align: center;
  margin: 10px 0;
}

.infoMessage {
  margin-top: 0 !important;
}
