@import 'src/theme/xtrf/index';

.list {
  list-style: none;
  border-left: 1px solid $secondary-grey-color;
  padding: 0;
}

.navLink {
  display: block;
  font-weight: 600;
  color: $text-secondary-color;
  border-left: 1px solid transparent;
  padding: 15px;
  margin-left: -1px;
  transition: 0.3s;

  &:hover {
    color: $primary-blue-color;
  }
}

.navLinkActive {
  color: $primary-blue-color;
  border-left: 1px solid $primary-blue-color;
}

.separateLine {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $secondary-grey-color;
  margin-left: 15px;
  margin-bottom: 2px;
  margin-top: 2px;
}
