@import 'src/theme/xtrf/index';

.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 30px;
  top: 30px;
  width: 310px;
  z-index: 1;
  box-shadow: 0 2px 5px 0 #cccccc;
}
.containerCollapsed {
  box-shadow: none;
  z-index: 0;
}
.headerWrapper {
  padding: 15px;
  background: hsla(0, 0%, 100%, 1);
  z-index: 2;
  transition: all 0.3s;
  border: 1px solid transparent;
}
.listWrapper {
  background: hsla(0, 0%, 100%, 1);
  z-index: 2;
  transform-origin: top;
  opacity: 1;
  transition: all 0.3s ease-out;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  min-height: 38px;
}

.workspaceListHeader {
  margin-top: 20px !important;
  margin-bottom: 15px !important;
  font-size: 16px !important;
}

.headerCollapsed {
  margin-bottom: 0;
}

.toggleIcon {
  font-size: 20px;
  cursor: pointer;
}

.toggleIconCollapsed {
  filter: invert(1);
}

.collapsed {
  color: $primary-grey-color;
  background: transparent;
  border: 1px solid $primary-grey-color;
  &:hover {
    border: 1px solid $secondary-grey-color;
  }
}

.separator {
  height: 1px;
  border: none;
  background-color: $secondary-grey-color;
  margin: 0 -15px;
}

.loadingBox {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingIndicator {
  width: 80px;
}

.errorMessage {
  margin: 30px 0;
}

.updown {
  transform: scaleY(0);
  opacity: 0;
}

.list {
  padding: 0 15px;
}
