@import 'src/theme/xtrf/index';

.message {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  padding: 0 10px;
  transition: background-color 0.3s ease-out;

  .content {
    padding: 5px 0;
    margin-right: 25px;
    font-size: $h4-font-size;
    a {
      color: #3b3b3b !important;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
      &:hover {
        border-bottom: 1px solid #222222;
      }
    }
    :global .emoji {
      font-size: 18px;
      vertical-align: top;
      margin: 0 1px;
    }
  }
  &:hover {
    background-color: $third-grey-color;
  }
}

.content {
  color: $text-secondary-color;
  word-break: break-word;
  white-space: pre-line;
  & > blockquote:not(:nth-child(1)) {
    margin-top: -10px;
  }
  & > ol,
  ul {
    white-space: pre-line;
    margin: -15px -22px -10px;
    line-height: 15px;
    & > li > ul {
      margin-bottom: -30px
    }
    & > li:nth-child(1) {
      margin-top: 5px;
    }
    & > li:not(:nth-child(1)) {
      margin-top: -10px;
    }
  }
}

.fileContent.content {
  a {
    color: $black;
    border-bottom: none;
    &:hover {
      border-bottom: none;
    }
  }
}

.metaTag {
  margin-top: $spacing-small;
  display: flex;
  align-items: center;
  max-width: 225px;
}

.date {
  color: $text-third-color;
  font-size: $h6-font-size;
}

.author {
  margin-right: $spacing-very-small;
  color: $text-secondary-color;
  font-weight: $h3-font-weight;
  font-size: $h4-font-size;
  display: inline-block;
  max-width: 120px;
  word-break: break-word;
  hyphens: auto;
}

b {
  font-weight: bold;
}
u {
  font-style: italic;
  text-decoration: unset;
}
i {
  text-decoration: line-through;
  font-style: unset;
}

.messageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.withoutMetaTag {
  justify-content: flex-end;
}

.dropdownHidden {
  display: none;
}

.dropdownWithoutMetaTag {
  top: 0;
}

.confirm {
  max-width: 400px;
}

.dropdownVisible {
  display: block;
  position: absolute;
  right: -5px;
  top: 4px;
}

.deleteConfirmationModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $light;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  z-index: 2;
}

.confirmText {
  padding: 0 10px;
  font-size: 14px;
}

.modalButtonsWrapper {
  margin-top: 15px;
}

.button {
  position: relative;
  display: block;
  border: 1px solid $secondary-grey-color;
  border-radius: 3px;
  min-height: 30px;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: $third-grey-color;
  }
  &:active {
    transform: translateY(1px);
  }
}

.blueButton {
  @extend .button;
  margin-right: 10px !important;
  color: white !important;
  background-color: $primary-color-1 !important;
  border: none !important;
  &:hover,
  &:focus {
    background-color: $primary-color-2 !important;
  }
  &:active {
    background-color: $primary-color-2 !important;
  }
}

.icon {
  margin-right: 5px;
}
