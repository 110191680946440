@import 'src/theme/xtrf/index';

.dropdownItemDanger {
  min-width: 220px;
  color: $error-color !important;
}

.modalHeader {
  margin-bottom: 25px !important;
  color: #d28c15 !important;
}

.buttonsWrapper {
  margin-top: 25px;
  display: flex;
  gap: 10px;
}

.modalContent {
  background-color: #fefdf8;
  color: #d28c15;
  padding: 25px !important;
}
