@import 'src/theme/xtrf/index';

.container {
  position: relative;
  outline: none;
  &:hover {
    .wrapperBig {
      opacity: 0.1;
    }
    .uploadIcon {
      display: block;
    }
  }
}

.wrapper {
  border-radius: 50%;
  min-width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light;
  font-size: $avatar-font-size;
  margin-right: 5px;
  background-position: center;
  background-size: cover;
}

.wrapperBig {
  @extend .wrapper;
  min-width: 80px;
  height: 80px;
  font-size: $avatar-font-size-big;
  margin-right: 0;
}

.uploadIcon {
  display: none;
  fill: $sixth-grey-color;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.border {
  border: 0.5px solid $secondary-grey-color;
}
