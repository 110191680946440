@import 'src/theme/xtrf/index';

.errorMessage {
  margin: 2px 0;
  color: $error-color;
  font-size: 13px;
  max-width: 100%
}

.deleteIcon {
  font-size: 1.1em !important;
  margin-left: 5px !important;
  margin-top: -2px !important;
  color: $error-color !important;
  cursor: pointer;
}
