@import 'src/theme/xtrf/index';

.button {
  position: relative;
  padding: 7px 12px !important;
  border: 1px solid $secondary-grey-color;
  border-radius: 3px;
  min-height: 30px;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: $third-grey-color;
  }
  &:active {
    transform: translateY(1px);
  }
}

.blueButton {
  @extend .button;
  color: white !important;
  background-color: $primary-color-1 !important;
  border: none !important;
  &:hover,
  &:focus {
    background-color: $primary-color-2 !important;
  }
  &:active {
    background-color: $primary-color-2 !important;
  }
}

.disabledButton {
  @extend .button;
  @extend .blueButton;
  background-color: $secondary-grey-color !important;
  &:hover {
    background-color: $secondary-grey-color !important;
  }
}
