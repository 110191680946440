.container {
  position: relative;
  min-height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {
  height: 100vh
}

.gridColumn {
  width: 450px !important;
}
