.ui.inverted.dimmer,
.ui.dimmer {
  transition: background-color 0.3s ease-out;
  background-color: transparent;
}

.modal-fade-in .ui.inverted.dimmer,
.confirm-fade-in .ui.dimmer {
  background-color: hsla(0, 0%, 0%, 0.6) !important;
}

.ui.dropdown .menu.transition {
  animation: fadeIn 0.3s ease-out;
}
