@import 'src/theme/xtrf/index';

.optionsIcon {
  color: #727272;
  margin: 0;
  font-size: 14px !important;
  border: 1px solid #727272;
  border-radius: 3px;
  height: 25px !important;
  width: 25px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.smallIcon {
  height: 23px !important;
  width: 23px !important;
}

.dropdown {
  pointer-events: all;
  z-index: 20;
}

.dropdownTrigger {
  background-color: unset;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0 5px;
}

.disabledItem {
  color: $fourth-grey-color !important;
}

.dropdownItem {
  position: relative;
  cursor: pointer;
  display: block;
  height: auto;
  text-align: left;
  border: none;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.78571429rem 1.14285714rem !important;
  font-size: 1rem;
  text-transform: none;
  font-weight: 400;
  box-shadow: none;
  &:hover {
    background: $third-grey-color;
    color: rgba(0, 0, 0, 0.95);
    z-index: 13;
  }
}

.smallDropdownItem {
  font-size: 13px;
  padding: 5px 10px !important;
  display: flex;
  align-items: center;
}
