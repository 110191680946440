@import 'src/theme/xtrf/index';

.header {
  margin-top: unset !important;
}

.headerTitle {
  font-size: $h3-font-size;
  font-weight: $h2-font-weight;
}

.userEmail {
  font-size: $h3-font-size;
  font-weight: $h4-font-weight;
}

.sectionTitle {
  margin-bottom: 15px;
  font-weight: $h3-font-weight;
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.removeAvatarWrapper {
  margin-top: 4px;
  font-size: 13px;
}

.link {
  color: #3b3b3b !important;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  cursor: pointer;
  opacity: 1 !important;
  &:hover {
    border-bottom: 1px solid #222222;
  }
}

.termsAndConditions {
  color: inherit !important;
  opacity: 1 !important;
}

.button {
  position: relative;
  display: block;
  margin: 10px auto !important;
  width: 100%;
  padding: 7px 0 !important;
  border: 1px solid $secondary-grey-color;
  border-radius: 3px;
  min-height: 30px;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: $third-grey-color;
  }
  &:active {
    transform: translateY(1px);
  }
}

.blueButton {
  @extend .button;
  color: white !important;
  background-color: $primary-color-1 !important;
  border: none !important;
  margin: 30px auto 0 !important;
  &:hover,
  &:focus {
    background-color: $primary-color-2 !important;
  }
  &:active {
    background-color: $primary-color-2 !important;
  }
}

.signOutButton {
  @extend .button;
  width: 100%;
  margin: 20px 0 !important;
}

.disabledButton {
  @extend .button;
  @extend .blueButton;
  background-color: $secondary-grey-color !important;
  &:hover {
    background-color: $secondary-grey-color !important;
  }
}

.closeIcon {
  position: absolute;
  fill: $primary-grey-color !important;
  top: 25px !important;
  right: 15px !important;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.dropdown {
  position: absolute !important;
  top: 29px;
  right: 50px;
}
.dropdownList {
  top: 23px !important;
  left: 10px !important;
}

.confirm {
  max-width: 350px;
  height: 120px !important;
}

.loaderContainer {
  padding: 46px;
  position: relative;
}

.infoAboutVersion {
  color: $text-third-color;
  font-size: 11px;
  padding-top: 10px;
}

.userWrapper {
  display: flex;
  align-items: center;
}
