@import 'src/theme/xtrf/index';

.container {
  padding: 20px 0;
}

.dateHeader {
  color: $fifth-grey-color !important;
  font-size: $h4-font-size !important;
  font-weight: $h1-font-weight !important;
}

.beginningOfConversation {
  color: $text-third-color;
  text-align: center;
  margin: 0 0 20px;
}

.divider {
  margin: 1rem 0 !important;
  padding: 0 10px;
}

.unreadDividerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}
