@import 'src/theme/xtrf/index';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 20px;
  padding-left: 10px;
}

.counter {
  font-size: 11px;
  margin-left: 3px;
}

.reactionWrapper {
  border-radius: 16px;
  background-color: $twelvth-grey-color;
  padding: 0 3px 0;
  margin-right: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  margin-bottom: 4px;
  &:hover {
    border: 1px solid $sixth-grey-color;
    box-sizing: border-box;
  }
}

.reactionWrapperAuthor {
  @extend .reactionWrapper;
  background-color: $sixth-red-color;
  &:hover {
    border: 1px solid $primary-color-1;
  }
}

.reaction {
  font-size: 16px;
}

.addReactionIcon {
  cursor: pointer;
}
