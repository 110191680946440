@import 'src/theme/xtrf/index';

.error {
  font-size: $small-input-font-size;
  color: $error-color;
  margin-top: $spacing-very-small;
}

.input {
  width: 100%;
  opacity: 1 !important;
  height: 34px !important;
  color: $input-color !important;
  border-radius: 0 !important;
  outline: none;
  border: $input-border !important;
  &:hover {
    border: $hover-input-border !important;
  }
  & > input {
    border: none !important;
  }
  &:focus-within {
    outline: $focus-input-border !important;
  }
}

.inputError {
  @extend .input;
  border: $input-error-border !important;
  &:hover {
    border: $input-error-border !important;
  }
  &:focus {
    outline: $focus-input-border !important;
    outline-offset: 0px;
  }
}
