@import 'src/theme/xtrf/index';

.numberInCircle {
  border-radius: 10px;
  width: 25px;
  height: 20px;
  background: $primary-color-1;
  text-align: center;
  color: #ffffff;
  font-size: 11px;
  font-weight: 900;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.big {
  width: 34px;
  height: 30px;
  font-size: 12px;
  border-radius: 14px;
}
