@import 'src/theme/xtrf/index.scss';

.header {
  padding-top: 15px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoIcon {
  height: 100px;
}

.title {
  padding-top: 15px;
  font-size: 24px;
  font-weight: 600;
}

.topBar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 5px;
  background-color: $primary-color-1;
}
