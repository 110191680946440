.page {
  min-height: 100vh;
  background-color: #eee;
}

.container {
  padding: 30px 40px 40px;
  display: grid;
  grid-template-columns: 0.2fr 1fr;
}

.content {
  margin-left: 45px;
  background-color: #fff;
}

.testModeLabelWrapper {
  border: 2px solid #f6bb57;
  background-color: #fefdf8;
  color: #d28c15;
  padding: 20px 30px;
  margin: 30px 40px 0;
}

.testModeLabelBoldText {
  font-weight: 600;
}
