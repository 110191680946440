@import 'src/theme/xtrf/index';

.header {
  font-size: $h2-font-size;
  font-weight: $h2-font-weight;
}

.container {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
}

.text {
  color: $text-third-color;
}

.linkButton {
  display: inline-block;
  border: 1px solid $secondary-grey-color;
  border-radius: 3px;
  padding: 7px 20px;
  background-color: $light;
  cursor: pointer;
  align-self: center;
  margin-top: 5px;
  color: $text-primary-color;

  &:hover {
    background-color: $third-grey-color;
    color: inherit;
  }
}
