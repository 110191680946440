@import 'src/theme/xtrf/index';

.currencyInputWrapper {
  display: flex;
}

.currencyInput {
  width: 100%;
  opacity: 1 !important;
  height: 34px !important;
  color: $input-color !important;
  border-radius: 0 !important;
  outline: none !important;
  border: $input-border !important;
  &:hover {
    border: $hover-input-border !important;
  }
  & > input {
    border: none !important;
  }
  &:focus-within {
    outline: none !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.currency {
  color: #969696;
  margin: -10px 0 5px;

  span {
    font-weight: 600;
    color: #898989;
  }
}
