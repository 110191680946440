@import 'src/theme/xtrf/index';

.error {
  margin: 7px 10px !important;
}

.menagerChatroomsContainer {
  padding: 0;
}

.placeholder {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.emptyStateText {
  color: $fifth-grey-color;
  font-size: 13px;
}

.group {
  padding: 0 10px 6px 10px;
  font-weight: 600;
  line-height: 26px;
  color: $fifth-grey-color;
}

.firstGroup {
  padding: 0 10px 6px 10px;
}

.divider {
  padding-bottom: 2px;
  border-bottom: 1px solid #cccccc;
}

.closedWrapper {
  padding-bottom: 10px;
}

.closedWrapperVendor {
  padding-top: 10px;
  padding-bottom: 10px;
}
