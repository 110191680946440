@import 'src/theme/xtrf/index';

.loaderContainer {
  padding: 46px;
  position: relative;
}

.confirm {
  max-width: 350px;
  height: 120px !important;
}

.leaveButton {
  background-color: $primary-color-1;
}
