@import 'src/theme/xtrf/index';

.wrapper {
  padding: 0 30px 40px;
  text-align: center;
}

.submitButton {
  margin-bottom: 12px !important;
  min-width: 155px !important;
  min-height: 40px !important;
}

.loginForm {
  margin-bottom: 40px;
  & a {
    color: $primary-grey-color;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
    &:hover,
    &:active {
      color: $text-secondary-color;
      border-bottom: 1px solid $text-secondary-color;
    }
  }
}

.signIn {
  display: inline-block;
  margin-bottom: 40px;
  color: $primary-grey-color;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  &:hover,
  &:active {
    color: $text-secondary-color;
    border-bottom: 1px solid $text-secondary-color;
  }
}

.header {
  margin: 20px 0 20px !important;
  font-weight: 400 !important;
}

.passwordInput {
  @extend .input;
  margin-bottom: 10px;
}
