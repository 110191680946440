.allMembersIcon {
  position: absolute;
  top: -3px;
}
.pendingIcon {
  position: absolute;
  top: 3px;
  left: 4px;
  height: 15px;
  width: 15px;
}
.exclamationIcon {
  position: absolute;
  top: 3px;
  left: 5px;
  font-size: 14px;
}
