@import 'src/theme/xtrf/index';

.container {
  position: relative;
  outline: none;
}

.preliminaryDropOverlay {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 130px;
}

.dropOverlay {
  @extend .preliminaryDropOverlay;
  border: dashed $primary-color-1 2px;
}

.dropHidden {
  display: none;
}

.text {
  margin-top: 20px;
  font-size: $h3-font-size;
  font-weight: $h1-font-weight;
}
