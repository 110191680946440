@import 'src/theme/xtrf/index';

.divider {
  width: 100%;
  background-color: $primary-color-1;
  height: 1px;
}

.unreadDividerText {
  white-space: nowrap;
  margin: 0 12px;
  font-weight: $h1-font-weight;
  color: $primary-color-1;
}
