@import 'src/theme/xtrf/index';

.wrapper {
  padding: 0 30px 40px;
  text-align: center;
}

.submitButton {
  display: block !important;
  margin-bottom: 12px !important;
  min-width: 155px !important;
  min-height: 40px !important;
}

.loginForm {
  margin-bottom: 40px;
  text-align: left;
  & a {
    color: $primary-grey-color;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
    &:hover,
    &:active {
      color: $text-secondary-color;
      border-bottom: 1px solid $text-secondary-color;
    }
  }
}

.loginInput {
  &:focus-within {
    outline: $focus-input-border !important;
  }
  & > div {
    height: 34px !important;
    border: $input-border;
    &:hover {
      border: $hover-input-border;
    }
  }
  & > div > input {
    color: $input-color !important;
    border-radius: 0 !important;
    border: none !important;
    &::placeholder {
      color: $input-placeholder-color !important;
    }
  }
  margin-bottom: 20px !important;
}

.signIn {
  display: inline-block;
  margin-bottom: 40px;
  color: $primary-grey-color;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  &:hover,
  &:active {
    color: $text-secondary-color;
    border-bottom: 1px solid $text-secondary-color;
  }
}

.header {
  margin: 0 0 20px !important;
  font-weight: 400 !important;
}

.info {
  text-align: left;
  border-radius: 0 !important;
  border: 1px solid $info-border-color !important;
  background-color: $info-background !important;
  box-shadow: none !important;
}

.infoHeader {
  color: $info-color !important;
}
