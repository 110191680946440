@import 'src/theme/xtrf/index';

.button {
  position: relative;
  display: block;
  margin: 10px auto !important;
  width: 100%;
  padding: 7px 0 !important;
  border: 1px solid $secondary-grey-color;
  border-radius: 3px;
  min-height: 30px;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: $third-grey-color;
  }
  &:active {
    transform: translateY(1px);
  }
}

.blueButton {
  @extend .button;
  color: white !important;
  background-color: $primary-color-1 !important;
  border: none !important;
  margin: 30px auto 0 !important;
  &:hover,
  &:focus {
    background-color: $primary-color-2 !important;
  }
  &:active {
    background-color: $primary-color-2 !important;
  }
}

.cancelButton {
  @extend .button;
  width: 100%;
  margin: 20px 0 !important;
}

.disabledButton {
  @extend .button;
  @extend .blueButton;
  background-color: $secondary-grey-color !important;
  &:hover {
    background-color: $secondary-grey-color !important;
  }
}

.cropperWrapper {
  position: relative;
  width: 100%;
  height: 175px;
  margin-top: 10px;
}

.errorWrapper {
  display: flex;
  align-items: center;
}

.errorMessage {
  color: $error-color;
  font-size: 13px;
  margin-left: 5px;
}

.errorIcon {
  font-size: 1.3em !important;
  color: $error-color;
  margin-bottom: 5px;
}
