@import 'src/theme/xtrf/index';

.wrapper {
  padding: 0 30px;
  padding-bottom: 40px;
  position: relative;
  text-align: left;
}

.form {
  text-align: center;
}

.submitButton {
  display: block !important;
  margin-bottom: 10px !important;
  background-color: $primary-color-1 !important;
  color: white !important;
  padding: 5px 20px !important;
  border: none !important;
  &:hover,
  &:focus {
    background-color: $primary-color-2 !important;
  }
  &:active {
    background-color: $primary-color-2 !important;
  }
}

.checkboxes {
  text-align: left;
  margin-bottom: 20px;
}

.termsAndConditionsCheckbox {
  margin-bottom: 10px;
}

.termsAndConditions {
  color: inherit !important;
  opacity: 1 !important;
  & .link {
    color: inherit !important;
    border-bottom: 1px dashed;
    cursor: pointer;
    opacity: 1 !important;
    & > input {
      border-radius: 0 !important;
    }
  }
}

.input {
  &:focus-within {
    outline: $focus-input-border !important;
  }
  & > div {
    opacity: 1 !important;
    height: 34px !important;
    border: $input-border;
    &:hover {
      border: $hover-input-border;
    }
  }
  & > div > input {
    color: $input-color !important;
    border-radius: 0 !important;
    border: none !important;
    &::placeholder {
      color: $input-placeholder-color !important;
    }
  }
}

.emailAddressInput {
  margin-bottom: 10px !important;
  opacity: 1 !important;
  @extend .input;
}

.passwordInput {
  margin-bottom: 20px !important;
  @extend .input;
}


.header {
  margin-bottom: 20px !important;
  margin-top: 0 !important;
  font-weight: 400 !important;
}
