@import 'src/theme/xtrf/index';

.header {
  box-shadow: 0 2px 2px rgba($fourth-grey-color, 0.5);
  background-color: #fff;
  padding: 0 40px;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 26px;
  margin: 0;
}

.userWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.userEmailWrapper {
  cursor: pointer;
}

.userEmailText {
  font-size: 15px;
  font-weight: 600;
}

.userAvatar {
  background-color: $third-grey-color;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.dropdownMenu {
  width: 100%;
}
