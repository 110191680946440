.container {
  min-height: 100vh;
  background: url('../../../../src/theme/xtrf/wallpapers/logo_texture.jpg')
    #fff no-repeat center center;
  height: 100%;
  position: relative;
  background-size: cover;
  display: flex;
  justify-content: center;
}
