@import 'src/theme/xtrf/index';

.container {
  padding: 3px 10px;
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: $third-grey-color;
  }
}

.header {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
}

.name {
  font-weight: $h4-font-weight;
  margin: 0 10px 0 30px;
  color: $primary-grey-color !important;
  word-break: break-all;
}

.allMembersRoom {
  font-weight: $h1-font-weight;
}

.link {
  color: #3b3b3b !important;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  cursor: pointer;
  opacity: 1 !important;
  &:hover {
    border-bottom: 1px solid #222222;
  }
}

.chatRoomOpen {
  color: $primary-color-1 !important;
}

.link.chatRoomOpen {
  border-bottom: 1px dashed $primary-color-1;
  &:hover {
    border-bottom: 1px solid $primary-color-2;
  }
}

.resendInvitation {
  position: absolute;
}
