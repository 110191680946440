@import 'src/theme/xtrf/index';

.multiselectField {
  border: $input-border;
  outline: none;
  text-align: left;
  line-height: 1.2em;
  padding: 0.7em 1em;
  font-size: $normal-input-font-size;
  position: relative;
  height: 34px;
  background-color: white;

  &:active:not(.disabled) {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
  }
  &:focus:not(.disabled) {
    background: #ffffff;
    box-shadow: none;
  }
  &:hover:not(.disabled) {
    border: 1px solid #969595;
    outline: 3px solid rgba(0, 0, 0, 0.12);
  }
}

.multiselectFieldDropdown {
  position: absolute!important;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}

.multiselectFieldDropdownMenu {
  width: 100% !important;
  max-height: 200px;
  overflow-y: auto!important;
  border-radius: 0!important;
}

.multiselectFieldDropdownMenuItem#option {
  padding: 0!important;
}

.multiselectFieldDropdownCheckbox {
  width: 100%;
  padding: 0.8rem 1.1rem;
}

.dropdownIcon {
  right: 10px;
  top: 10px;
  position: absolute;
}

.multiselectFieldSelectionLabel {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  display: inline-block;
}

.multiselectFieldErrorLabel {
  font-size: $small-input-font-size;
  color: $error-color;
  margin-top: 5px;
}

.multiselectFieldError {
  border: 1px solid $error-color;
}

.disabled {
  background-color: $disabled-input-background-color;
  color: $disabled-input-color;
  border: $disabled-input-border;
  cursor: auto;
}

.formFieldContainer {
  padding-left: 1.85em;
}
.formFieldLabel {
  padding-left: 1.85em;
  line-height: 2em;
}
