@import 'src/theme/xtrf/index';

.placeholder {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.emptyStateText {
  color: $fifth-grey-color;
  font-size: 13px;
}

.list {
  display: flex;
  flex-direction: column;
}
