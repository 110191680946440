@import 'src/theme/xtrf/index';
.container {
  display: flex;
}
.listContent {
  max-height: 400px;
  overflow-y: auto;
}

.unreadMessagesIndicator {
  margin: 0 5px;
}

.radioContainer {
  display: inline-block;
  width: 88%;
}

.popup {
  max-width: 200px;
  transition-delay: .8s;
  text-align:center
}

