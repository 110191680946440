@import 'src/theme/xtrf/index';

.button {
  position: relative;
  top: 50%;
  width: 70px;
  height: 40px;
  overflow: hidden;
  border: 1px solid $secondary-grey-color;
  border-radius: 4px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.switch {
  z-index: 2;
}

.switchButton .switch:before {
  content: 'Off';
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 40px;
  color: $text-primary-color;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-right: 1px solid $secondary-grey-color;
  border-radius: 4px;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.switchButton .checkbox:active + .switch:before {
  width: 60px;
}

.switchButton .checkbox:checked:active + .switch:before {
  margin-left: -26px;
}

.switchButton .checkbox:checked + .switch:before {
  content: 'On';
  left: 18px;
  background-color: $primary-blue-color;
  color: $light;
  border-right: none;
}
