@import 'src/theme/xtrf/index';

.outerCircle {
  border-radius: 50%;
  border: 1px solid $secondary-grey-color;
  height: 16px;
  min-width: 16px;
  position: relative;
  margin-right: 10px;
  &:hover {
    border: 1px solid $eighth-grey-color;
  }
}

.outerCircleActive {
  @extend .outerCircle;
  border: 1px solid $primary-color-1;
}

.innerCircle {
  position: absolute;
  background: $primary-color-1;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radioButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  padding-left: 3px;
  position: relative;
}

.radioButtonSelected {
  pointer-events: none;
  & > .iconContainer {
    pointer-events: all;
  }
}

.radioButtonDisabled {
  cursor: not-allowed;
  color: $text-third-color;
}
