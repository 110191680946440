@import 'src/theme/xtrf/index';

.titleExtraWrapper {
  display: flex;
  align-items: center;
}

.tableWrapper {
  max-height: 55vh;
  min-height: 55vh;
  overflow: auto;
}

.optionsIcon {
  color: #727272;
  margin: 0 !important;
  border: 1px solid #727272;
  border-radius: 3px;
  height: 25px !important;
  width: 25px !important;
}

.searchInput {
  margin-right: 10px;
  min-width: 200px;
}
