@import 'src/theme/xtrf/index';

.message {
  border-radius: 0 !important;
  text-align: left;
  box-shadow: none !important;
}

.error {
  @extend .message;
  border: 1px solid $error-border-color !important;
  background-color: $error-background !important;
}

.errorHeader {
  color: $error-color !important;
  font-weight: 600 !important;
}

.content {
  color: $error-color !important;
  margin: 0 !important;
  font-weight: 100 !important;
}

.info {
  @extend .message;
  border: 1px solid $info-border-color !important;
  background-color: $info-background !important;
}

.infoHeader {
  color: $info-color !important;
  font-weight: 600 !important;
}

.success {
  @extend .message;
  border: 1px solid $success-border-color !important;
  background-color: $success-background !important;
}

.successHeader {
  color: $success-color !important;
  font-weight: 600 !important;
}
