@import 'src/theme/xtrf/index';

.mainWrapper {
  width: 300px;
  min-width: 300px;
  opacity: 1;
  pointer-events: all;
  border: 1px solid $fourth-grey-color;
  background-color: #fff;
  outline: none;
  @media only screen and (max-width: 1340px) {
    width: 270px;
    min-width: 270px;
  }
}

.messageContainer {
  position: relative;
  background-color: #fff;
}

.messageWrapper {
  overflow-x: hidden;
  overflow-y: auto;
  height: 415px;
  max-width: 100%;
  white-space: pre-line;
  transition: height 0.3s ease-out;
}

.messageWrapperNoScroll {
  @extend .messageWrapper;
  overflow: hidden;
  width: 99%;
}

.messageWrapperWithAlert {
  height: 340px;
}

.topOfChatInfo {
  margin-top: 10px !important;
}

.minimalized {
  .messageWrapper {
    height: 0 !important;
    opacity: 0 !important;
  }
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
}

.infoMessage {
  margin: 20px 10px !important;
}

.chatInputContainer {
  width: 100%;
  border-top: $input-border;
  line-height: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative;
}

.textarea {
  min-height: 25px;
  width: 100%;
  border: none;
  padding: 10px 70px 10px 10px;
  font-family: $font-family;
  color: $text-secondary-color;
  resize: none;
  &:focus {
    outline: none;
  }
}

.iconWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.icon {
  position: absolute;
  bottom: 10px;
  font-size: 1.5rem !important;
  cursor: pointer;
}

.iconEmoji {
  @extend .icon;
  right: 44px;
  fill: $sixth-grey-color;
  &:hover {
    fill: $seventh-grey-color;
  }
}

.iconSend {
  @extend .icon;
  right: 14px;
  fill: $sixth-grey-color;
}

.iconSendActive {
  @extend .iconSend;
  fill: $primary-color-2;
}

.emojiPicker {
  display: none;
}

:global {
  .emoji-mart-bar {
    display: none;
  }
  .emoji-mart-scroll {
    height: 100% !important;
    border: none !important;
    overflow-y: hidden !important;
  }
  .emoji-mart .emoji-mart-emoji {
    outline: none !important;
  }
}

.emojiPickerVisible {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  width: 100%;
  & > div {
    width: 100% !important;
    border: none !important;
    min-height: 100%;
  }
}

.alert {
  height: 75px !important;
  border-radius: 0 !important;
  background-color: #fefdf8 !important;
  color: #cf8403 !important;
  border-bottom: 1px solid #f3aa2e;
  box-shadow: unset !important;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.alertHid {
  border-bottom: none;
}

.uploadInputContainer {
  cursor: pointer;
  padding: 0 3px 9px 5px;
  align-self: flex-end;
}

.uploadInput {
  outline: none;
}

.filesContainer {
  position: absolute;
  width: 100%;
  z-index: 2;
}

:global {
  ul,
  ol,
  blockquote {
    margin: 0;
  }
  blockquote {
    padding-left: 1rem;
    border-left: 0.25em solid #dfe2e5;
  }
  span.emoji {
    display: -moz-inline-box;
    -moz-box-orient: vertical;
    display: inline-block;
    vertical-align: baseline;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    width: 1em;
    height: 1em;
    background-size: 1em;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: 50%, 50%;
    background-size: contain;
  }

  span.emoji-sizer {
    line-height: 0.81em;
    font-size: 1em;
    margin: -2px 0;
  }

  span.emoji-outer {
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    height: 1em;
    width: 1em;
  }

  span.emoji-inner {
    display: -moz-inline-box;
    display: inline-block;
    text-indent: -9999px;
    width: 100%;
    height: 100%;
    vertical-align: baseline;
    *vertical-align: auto;
    *zoom: 1;
  }

  img.emoji {
    width: 1em;
    height: 1em;
  }
}
