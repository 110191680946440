.columnHeaderWrapper {
  cursor: pointer;

  &:hover {
    .sortArrow {
      opacity: 1;
    }
  }
}

.sortArrow {
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 8px;
}

.arrowVisible {
  opacity: 1;
}
