@import 'src/theme/xtrf/index';

.container {
  padding-bottom: 15px;
}

.panelElement {
  height: 30px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: $third-grey-color;
  }
}

.button {
  @extend .panelElement;
  cursor: pointer;
}

.header {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  padding: 0 15px !important;
}

.username {
  @extend .panelElement;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoAboutVersion {
  color: $text-third-color;
  font-size: 11px;
  padding-top: 10px;
}
