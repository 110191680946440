@import 'src/theme/xtrf/index';

.titleWrapper {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  transition: background-color 0.3s ease-out;
  height: 62px;
  &:hover {
    background-color: $third-grey-color;
    cursor: pointer;
  }
}

.titleHeader {
  margin-bottom: 0 !important;
  font-weight: 600 !important;
  width: 230px;
  word-break: break-word;
  max-width: calc(100% - 41px);
}

.subHeader {
  display: flex !important;
  align-items: baseline !important;
  margin-top: 5px !important;
  color: black !important;
  position: relative;
  line-height: unset !important;
}

.chatWindowIcon {
  font-size: 1em !important;
  cursor: pointer;
  width: 20px;
  text-align: center;
  margin-left: 5px;
  fill: $fifth-grey-color;
}

.minimizeIcon {
  @extend .chatWindowIcon;
  width: 15px;
  height: 15px;
}

.unreadMessagesWrapper {
  display: inline-block;
  margin-left: 5px;
}

.chatRoomIcon {
  margin-right: 8px !important;
  font-size: 1.2em !important;
  color: $primary-grey-color !important;
}

.name {
  font-weight: 400;
  margin: 0 5px 0 25px;
  word-break: break-word;
  color: $primary-grey-color !important;
}

.chatTitleContainer {
  display: flex;
}

.chatTitleContainerColumn {
  display: flex;
  flex-direction: column;
}

.projectDisplayId {
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.projectName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.appIcon {
  width: 24px;
  margin: 0 10px 0 5px;
  opacity: 0.7;
}
