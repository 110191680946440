$import-google-fonts: true !default;
$font-url: 'https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700&display=swap&subset=latin-ext' !default;
$font-name: 'Open Sans' !default;
$font-family: $font-name, 'Helvetica Neue', Arial, Helvetica, sans-serif !default;
$use-custom-scrollbars: true !default;
$icons-font-path: '../../icons' !default;
$flags-image-path: '../../images' !default;
$rootFontSize: 14px;

// Main
$black: #000;
$light: #fff;
$primary-grey-color: #3b3b3b;
$primary-blue-color: #0424D5;
$secondary-blue-color: #031ca3;
$sixth-red-color: #fde3e2;
$seventh-red-color: #fcf4e7;
$eighth-red-color: #f6f3ef;
$secondary-grey-color: #cccccc;
$third-grey-color: #f3f3f3;
$fourth-grey-color: #999;
$fifth-grey-color: #5e5e5e;
$sixth-grey-color: #8d8c8c;
$seventh-grey-color: #5b5b5b;
$eighth-grey-color: #969696;
$ninth-grey-color: #c2c2c2;
$tenth-grey-color: #d3d3d3;
$eleventh-grey-color: #808080;
$twelvth-grey-color: #f0f0f0;
$thirteenth-grey-color: #e8e8e8;
$fourtheenth-grey-color: #6e6564;
$fifteenth-grey-color: #dddddd;
$sixteenth-grey-color: #f2f2f2;

$primary-color-1: $primary-blue-color;
$primary-color-2: $secondary-blue-color;
$error-color: rgb(235, 47, 38);

// Text
$text-primary-color: #3b3b3b;
$text-secondary-color: #222222;
$text-third-color: #898989;
$text-fourth-color: #303030;

//Inputs
$input-border: 1px solid $secondary-grey-color;
$input-left-padding: 10px;
$input-placeholder-color: #898989;
$input-color: #3b3b3b;
$hover-input-border: 1px solid #969595;
$disabled-input-border: 1px solid #e6e6e6;
$disabled-input-color: #3b3b3b;
$disabled-input-background-color: #e8e8e8;
$disabled-input-placeholder-color: #c2c2c2;
$focus-input-border: 3px solid rgba(0, 0, 0, 0.12);
$input-error-border: 1px solid $error-color;

//height
$small-input-height: 26px;
$normal-input-height: 34px;
$large-input-height: 34px;
$x-large-input-height: 45px;

//font-size
$small-input-font-size: 11px;
$normal-input-font-size: 13px;
$large-input-font-size: 13px;
$x-large-input-font-size: 16px;

// headers
$h1-font-size: 26px;
$h2-font-size: 20px;
$h3-font-size: 16px;
$h4-font-size: 13px;
$h5-font-size: 14px;
$h6-font-size: 12px;

$h1-line-height: 30px;
$h2-line-height: 26px;
$h3-line-height: 20px;
$h4-line-height: 18px;

$h1-font-weight: 600;
$h2-font-weight: 600;
$h3-font-weight: 600;
$h4-font-weight: 400;

// spacings
$spacing-big: 30px;
$spacing-medium: 25px;
$spacing-standard: 20px;
$spacing-medium-small: 16px;
$spacing-small: 10px;
$spacing-very-small: 5px;

// error
$error-background: #fef9f7;
$error-color: #ba3325;
$error-border-color: #d54e3f;

// info
$info-background: #f4f6f7;
$info-color: #275f7a;
$info-border-color: #97bdc6;

// success
$success-background: #f4f8f0;
$success-color: #4a6209;
$success-color-2: #5b740a;
$success-border-color: #91a468;
//drag and drop
$preliminary-icon-wrapper: $fifteenth-grey-color;
$preliminary-icon: $fifth-grey-color;
$icon-wrapper: $primary-color-1;
$icon: $light;
//avatar
$avatar-font-size: $h4-font-size;
$avatar-font-size-big: 32px;
