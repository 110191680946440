@import 'src/theme/xtrf/index';

.timePopper {
  box-shadow: 0px 0px 20px 5px rgba(66, 68, 90, 0.2);
  margin-bottom: 10px;
  padding-bottom: 0 !important;

  :global {
    .react-datepicker {
      border-radius: 0;
      border-color: #e4e4e4;
      display: block;

      .react-datepicker__header {
        display: none !important;
      }

      li {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        height: 25px !important;
        margin: 5px !important;
        border-radius: 7px;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;

        &:hover,
        &.react-datepicker__time-list-item--selected {
          font-weight: bold;
          color: rgb(54, 107, 160) !important;
          background-color: rgb(234, 245, 254) !important;
        }
      }
    }
  }
}

.datePopper {
  box-shadow: 0px 0px 20px 5px rgba(66, 68, 90, 0.2);
  margin-bottom: 10px;
  padding-bottom: 0 !important;

  :global {
    .react-datepicker {
      border-radius: 0;
      border-color: #e4e4e4;
      display: block;
      font-family: 'Open Sans', sans-serif;
    }

    .react-datepicker__header {
      border-bottom: 0;
      padding: 20px 0 5px;
      background: #fff;

      .DateTimePicker_datepickerHeader__3xIIu {
        padding: 5px 10px;
        font-family: 'Open Sans', sans-serif;
      }

      i {
        color: #216ba5 !important;
        font-size: 20px !important;
      }
    }

    .react-datepicker__day-names {
      background-color: #fff;
      text-transform: uppercase;
      font-size: 13px;

      .react-datepicker__day-name {
        color: #bebebe;
        margin: 6px 2px;
        font-weight: 600;
        width: 2.3rem;
        line-height: 2.3rem;
      }
    }

    .react-datepicker__month {
      background-color: #fff;
      margin: 0;
      padding: 10px 10px 15px;

      .react-datepicker__day {
        color: #3b3b3b;
        margin: 6px 2px;
        width: 2.3rem;
        line-height: 2.3rem;
        border-radius: 50%;
        font-size: 13px;
        font-weight: 500;

        &.react-datepicker__day--selected {
          border-radius: 50%;
          font-weight: bold;
          color: #fff;
        }

        &.react-datepicker__day--disabled {
          color: #bebebe;
        }

        &.react-datepicker__day--outside-month {
          color: #dbdbdb;
        }
      }
    }
  }
}

.input {
  width: 100%;
  opacity: 1 !important;
  height: 34px !important;
  color: $input-color !important;
  border-radius: 0 !important;
  outline: none !important;
  border: $input-border !important;

  &:hover {
    border: $hover-input-border !important;
  }
  & > input {
    border: none !important;
  }
  &:focus-within {
    outline: $focus-input-border !important;
  }
}

.errorInput {
  border-color: #ba3325 !important;

  &:hover {
    border-color: #ba3325 !important;
  }
}

.inputErrorMessage {
  color: #ba3325;
  font-size: 11px;
  margin-top: 2px;
}

.dateTimePickerWrapper {
  display: flex;
  justify-content: space-between;
}

.datePicker {
  max-width: 130px;
  margin-right: 10px;
}

.timePicker {
  max-width: 95px;
}

.datePickerWrapper {
  width: auto;
}

.icon {
  font-size: 14px !important;
  border-left: $input-border !important;
}

.datepickerHeader {
  margin-top: -7px;
  padding-bottom: 6px;
  padding-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.arrowIcon {
  font-size: 23px !important;
  cursor: pointer;
  color: $eighth-grey-color !important;

  &:hover {
    color: $fifth-grey-color !important;
  }
}

.select {
  border: 0;
  font-size: 14px;
  outline: none;
  text-align-last: right;
  background-color: inherit;
  font-weight: bold;
}

.timezone {
  color: #969696;
  margin: -10px 0 5px;

  span {
    font-weight: 600;
    color: #898989;
  }
}
