.testModeLabelWrapper {
  border-top: 2px solid #f6bb57;
  border-bottom: 2px solid #f6bb57;
  background-color: #fefdf8;
  color: #f6bb57;
  padding: 20px 30px;
}

.testModeLabelBoldText {
  font-weight: 600;
}
