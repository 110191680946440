@import '../globals/variables';

.ant-table-thead > tr > th {
  background-color: transparent;
  padding-bottom: 8px;
  border-bottom: 1px solid $secondary-grey-color;
  color: $text-third-color;
  font-weight: 600;

  &::before {
    display: none;
  }
}
